<template>
  <b-container class="all-courses__wrapper pl-0 pr-0">
    <div
      class="all-courses__count mt-3"
      v-if="!isCoursesLoading || allCourses.length > 0"
    >
      <p>{{ $t("search_course.results_found", { count: getCount }) }}</p>
    </div>
    <pagination
      :isLoadMore="isLoadMore"
      :showEmptyState="!isCoursesLoading && allCourses.length === 0"
      @onLoadMore="loadMore"
    >
      <template v-slot:course-card>
        <div class="ld-sec__grid-layout">
          <div
            class="cd-list--cards"
            v-for="(course, index) in allCourses"
            :key="index"
          >
            <!-- TODO update to didactica cards -->
            <CourseCard
              :courseImage="
                course.image_url
                  ? course.image_url
                  : require('@/assets/images/illustration/course-illustration.svg')
              "
              :org="course.org"
              :courseTitle="course.title"
              :language="language"
              :pacing="course.pacing"
              :courseLanguage="course.language"
              :course="course"
              @click="onCard(course)"
            />
          </div>
        </div>
      </template>
      <template v-slot:card-loader>
        <b-row
          class="w-100 row-space mb-3"
          v-if="isCoursesLoading && allCourses.length === 0"
        >
          <b-col
            cols="12"
            md="4"
            lg="3"
            class="mb-3"
            v-for="c in [1, 2, 3, 4]"
            :key="c"
          >
            <shimmer variant="landing-course-card" />
          </b-col>
        </b-row>
      </template>
    </pagination>
  </b-container>
</template>
<script>
import { mapGetters } from "vuex";
// mixins
import getCoursesMixin from "./mixins/getCourses";
import redirectToCourseDetail from "@/core/mixins/redirectToCourseDetail.js";

export default {
  computed: {
    ...mapGetters(["language"])
  },
  mixins: [getCoursesMixin, redirectToCourseDetail],
  mounted() {
    this._getAllCourses();
  },
  methods: {
    onCard(c) {
      this.navToCourseDetail(c);
    }
  }
};
</script>
<style scoped lang="scss">
.all-courses__wrapper {
  padding: 0px 100px 60px 100px;
  margin-top: $landing_page_common_margin_top;
  .ld-sec__grid-layout {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(
      auto-fill,
      minmax(clamp(25% - 24px, 218px, 100%), 1fr)
    );
    gap: 24px;
  }
  .row-space {
    margin-top: 2rem;
    @media (min-width: 500px) and (max-width: 768px) {
      .all-courses__card {
        width: 50%;
      }
    }
  }
  .all-courses__count {
    p {
      font-family: $font-family;
      font-style: normal;
      font-weight: 500;
      font-size: 22px;
      line-height: 28px;
      display: flex;
      color: $brand-public-secondary;
      margin-top: 64px;
    }
  }
  .button {
    left: 0%;
    right: 0%;
    top: 0%;
    bottom: 0%;
    width: 50%;
    height: 50%;
    background: #0057e0;
    border-radius: 5px;
    margin-top: 38px;
  }
  .courses {
    font-family: $font-family-source-sans;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 23px;
    text-align: center;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    color: #ffffff;
  }
}
@media only screen and (max-width: 768px) {
  .all-courses__wrapper {
    padding: 0px 16px 60px 16px !important;
    margin-top: 0px !important;
  }
}
</style>
