<template>
  <div class="main-wrapper" :class="name">
    <div class="container-fluid main-fluid">
      <b-container class="mb-5 pb-4 pr-0 pl-0">
        <!-- courses search page layout -->
        <b-row
          class="justify-content-md-center flex-column align-items-center"
          v-if="name == 'courses'"
        >
          <b-col md="auto">
            <p class="main-heading">{{ mainHeading }}</p>
          </b-col>
          <b-col md="auto">
            <p class="main-text">{{ mainSubHeading }}</p>
          </b-col>
        </b-row>
        <!-- landing page layout -->
        <b-row v-else>
          <b-col lg="6" class="main-block">
            <p class="main-heading">{{ mainHeading }}</p>
            <p class="main-text">{{ mainSubHeading }}</p>
            <div class="d-flex flex-row mt-4 main-wrapper__landing">
              <b-input-group class="lp-main__search w-90">
                <img
                  src="@/assets/images/landing/icons/search.svg"
                  alt="search"
                  width="16"
                  height="16"
                  class="lp-main__search--icon"
                  @click="onSearchText"
                />
                <b-form-input
                  type="text"
                  v-model="searchCourse"
                  :class="name == 'courses' ? 'position-relative' : ''"
                  :placeholder="
                    $t('landing_page.main_section.search.placeholder')
                  "
                  @keypress.enter="onSearchText"
                ></b-form-input>
                <img
                  src="@/assets/images/icons/close-black.svg"
                  alt="search"
                  width="14"
                  height="14"
                  class="lp-main__search--close"
                  v-if="searchCourse !== ''"
                  @click="searchCourse = ''"
                />

                <template #append>
                  <Button
                    variant="primary-lighter"
                    className="text-uppercase"
                    @click="onSearchText"
                    >{{ $t("general.search") }}</Button
                  >
                  <img
                    src="@/assets/images/landing/icons/search-with-bg.svg"
                    alt="search"
                    class="lp-main__search--xs"
                    @click="onSearchText"
                  />
                </template>
              </b-input-group>
            </div>
          </b-col>
          <b-col md="6" class="lp-main__banner">
            <img
              src="@/assets/images/landing/main-section-img.png"
              alt="section"
              class="lp-main__banner"
            />
          </b-col>
        </b-row>

        <div
          :class="
            name === 'courses'
              ? 'justify-content-md-center mt-4 main-wrapper__course'
              : 'd-none'
          "
        >
          <b-input-group class="lp-main__search">
            <img
              src="@/assets/images/landing/icons/search.svg"
              alt="search"
              width="16"
              height="16"
              class="lp-main__search--icon"
              @click="onSearchText"
            />
            <b-form-input
              type="text"
              v-model="searchCourse"
              :class="name == 'courses' ? 'position-relative' : ''"
              :placeholder="$t('landing_page.main_section.search.placeholder')"
              @keypress.enter="onSearchText"
            ></b-form-input>
            <img
              src="@/assets/images/icons/close-black.svg"
              alt="search"
              width="14"
              height="14"
              class="lp-main__search--close"
              v-if="searchCourse !== ''"
              @click="searchCourse = ''"
            />

            <template #append>
              <Button
                variant="primary-lighter"
                className="text-uppercase"
                @click="onSearchText"
                >{{ $t("landing_page.main_section.search.button") }}</Button
              >
              <img
                src="@/assets/images/landing/icons/search-with-bg.svg"
                alt="search"
                class="lp-main__search--xs"
                @click="onSearchText"
              />
            </template>
          </b-input-group>
        </div>
      </b-container>
    </div>
  </div>
</template>
<script>
import Button from "@/components/WrapperComponents/Button.vue";
import getCoursesMixin from "@/components/SearchCourse/mixins/getCourses";

export default {
  props: {
    name: {
      type: String,
      default: "landing"
    }
  },
  mixins: [getCoursesMixin],
  components: { Button },
  computed: {
    mainHeading() {
      if (this.name === "courses") {
        return this.$t("search_course.main.heading");
      } else {
        return this.$t("landing_page.main_section.heading");
      }
    },
    mainSubHeading() {
      if (this.name === "courses") {
        return this.$t("search_course.main.subheading");
      } else {
        return this.$t("landing_page.main_section.sub_heading");
      }
    }
  },
  data() {
    return {
      searchCourse: ""
    };
  },
  watch: {
    getSearchText(newVal) {
      if (newVal !== this.search) {
        this.searchCourse = newVal;
      }
    },
    // added logic for search clear
    // check if value is made empty by clicking close icon in search bar
    searchCourse(newVal, oldVal) {
      if (oldVal !== "" && newVal === "") {
        this.$store.commit("SET_SEARCH_TEXT", this.searchCourse);
      }
    }
  },
  mounted() {
    if (this.name === "courses") {
      this.searchCourse = this.getSearchText;
    }
    if (this.name === "landing") {
      this.searchCourse = "";
      this.$store.commit("SET_SEARCH_TEXT", this.searchCourse);
    }
  },
  methods: {
    onSearchText() {
      this.$store.commit("SET_SEARCH_TEXT", this.searchCourse);
      if (this.name === "landing") {
        this.$router.push({ name: "SearchCourses" });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.main-wrapper {
  background-color: #f7f8fa;
  &.landing {
    background-image: url("~@/assets/images/landing/main-bg.svg");
    background-repeat: no-repeat;
    background-position: bottom;
    min-height: 250px;
    height: 530px;
    background-attachment: fixed;
    background-size: cover;
    .main-heading {
      @include heading;
      text-align: left;
    }
    .main-text {
      text-align: left;
    }
    .main-fluid {
      height: 616px;
    }
  }
  &.courses {
    background-image: url("~@/assets/images/landing/main-bg-courses.svg");
    background-repeat: no-repeat;
    background-position: center;
    min-height: 250px;
    background-attachment: fixed;
    background-size: cover;
  }

  .main-heading {
    @include heading;
    max-width: 600px;
    margin-top: 128px;
  }
  .main-text {
    font-weight: 400;
    font-size: 21px;
    line-height: 30px;
    text-align: center;
    max-width: 600px;
    color: $brand-public-secondary;
  }
  .lp-main__banner {
    img {
      width: 100%;
      top: 15%;
      left: 4%;
      position: absolute;
    }
  }
  .full-rectangle-img {
    left: -17px;
    top: -85px;
  }
  .main-wrapper__landing {
    margin-bottom: 128px;
    .lp-main__search {
      width: 100%;
    }
  }
  .main-wrapper__course {
    .lp-main__search {
      .lp-main__search--close {
        right: 300px;
      }
    }
  }
  .lp-main__search {
    width: 80%;
    margin-right: auto;
    margin-left: auto;
    position: relative;
    .lp-main__search--icon {
      position: absolute;
      top: 38%;
      z-index: 6;
      left: 20px;
      cursor: pointer;
    }
    .lp-main__search--close {
      position: absolute;
      right: 170px;
      top: 25px;
      cursor: pointer;
      z-index: 3;
      margin-right: 15px;
    }
    input {
      padding: 18px 24px 18px 55px;
      height: 65px;
      box-shadow: 0px 32px 40px rgb(0 0 0 / 10%);
      border-radius: 5px 0px 0px 5px;
      border: none;
      &:focus {
        border: none;
      }
    }
    .btn {
      border-radius: 0px 5px 5px 0px !important;
      box-shadow: 0px 32px 40px rgb(0 0 0 / 10%);
      font-weight: 600;
      height: auto;
      padding: 0.375rem 0.5rem;
      min-width: 150px;
    }
    .lp-main__search--xs {
      display: none;
      position: absolute;
      right: 10px;
      top: 16%;
    }
  }
  .lp-main__image {
    width: 100%;
    position: relative;
    z-index: 100;
  }
}

// arabic alignment
[dir="rtl"] {
  .main-wrapper {
    .lp-main__banner {
      img {
        left: auto;
        right: 4%;
      }
    }
    .lp-main__search {
      .lp-main__search--icon {
        left: auto;
        right: 20px;
      }
      input {
        padding: 18px 55px 18px 24px;
      }
      .btn {
        border-radius: 5px 0px 0px 5px !important;
      }
      .lp-main__search--xs {
        right: auto;
        left: 10px;
      }
      .lp-main__search--close {
        left: 170px !important;
        right: unset !important;
      }
    }
    &.courses {
      .lp-main__search--close {
        left: 190px !important;
        right: unset !important;
      }
    }
  }
}

// french alignment
.app-fr {
  .main-wrapper {
    .main-wrapper__course {
      .lp-main__search {
        .lp-main__search--close {
          right: 390px;
        }
      }
    }
  }
}

// chinese alignment
.app-zh {
  .main-wrapper {
    .main-wrapper__course {
      .lp-main__search {
        .lp-main__search--close {
          right: 170px;
        }
      }
    }
  }
}

// spanish alignment
.app-es {
  .main-wrapper {
    .main-wrapper__course {
      .lp-main__search {
        .lp-main__search--close {
          right: 320px;
        }
      }
    }
  }
}

// russian alignment
.app-ru {
  .main-wrapper {
    .main-heading {
      font-size: 40px;
      line-height: 52px;
      margin-top: 60px;
    }
    .main-text {
      font-size: 18px;
    }
  }
}

@media (min-width: 568px) and (max-width: 768px) {
  .landing {
    height: 560px !important;
  }
  .main-heading {
    text-align: center !important;
    font-size: 35px !important;
    max-width: 100% !important;
  }
}
@media (min-width: 300px) and (max-width: 334px) {
  .landing {
    height: 700px !important;
  }
  .main-heading {
    font-size: 35px;
    line-height: 55px;
    text-align: center !important;
    margin-top: 128px;
  }
  .main-text {
    font-size: 18px;
    text-align: center !important;
  }
}

@media (min-width: 334px) and (max-width: 392px) {
  .main-heading {
    font-size: 45px;
    line-height: 55px;
    text-align: center !important;
  }
  .main-text {
    font-size: 20px;
    text-align: center !important;
  }
}

@media (min-width: 392px) and (max-width: 568px) {
  .landing {
    height: 600px !important;
  }
  .main-heading {
    font-size: 45px;
    line-height: 55px;
    text-align: center !important;
  }
  .main-text {
    font-size: 20px;
    text-align: center !important;
  }
}

@media (min-width: 768px) and (max-width: 990px) {
  .landing {
    height: 500px !important;
  }
  .main-heading {
    font-size: 48px !important;
    max-width: 100% !important;
    text-align: center !important;
  }
}
@media screen and (max-width: 990px) {
  [dir="rtl"] {
    .main-wrapper {
      .lp-main__search {
        .lp-main__search--close {
          left: 70px !important;
          right: unset !important;
        }
      }
      &.courses {
        .lp-main__search--close {
          left: 70px !important;
          right: unset !important;
        }
      }
    }
  }
  .main-wrapper {
    .main-text {
      text-align: center !important;
      max-width: 100% !important;
    }
    .lp-main__image {
      display: none;
    }
    .lp-main__banner {
      display: none;
    }
    .lp-main__search {
      width: 100%;
      .btn,
      .lp-main__search--icon {
        display: none;
      }
      .lp-main__search--xs {
        display: block;
        z-index: 4;
      }
      .lp-main__search--close {
        right: 70px !important;
      }
      input {
        padding: 10px 16px;
      }
    }
    .textButton {
      display: none;
    }
  }
}

@media screen and (max-width: 516px) {
  .main-wrapper {
    &.landing {
      background-image: url("~@/assets/images/landing/main-bg-xs.svg");
      height: auto;
    }
  }
  .app-hy {
    .main-wrapper {
      &.landing {
        .main-heading {
          font-size: 38px;
        }
      }
    }
  }
}

.textTnput {
  width: 100%;
  height: 65px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  display: flex;
  align-items: center;
  color: #8c97ac;
}
.textButton {
  width: 100%;
  height: 65px;
  background: #0057e0;
  border-radius: 0px 5px 5px 0px;
  font-family: $font-family;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 1.25px;
  text-transform: uppercase;
}
@media screen and (max-width: 990px) {
  .main-wrapper .main-heading {
    margin-top: 71px;
  }
}
</style>
