<template>
  <LandingPageLayout>
    <Main name="courses" />
    <AllCourses />
  </LandingPageLayout>
</template>

<script>
import LandingPageLayout from "../components/Layout/LandingPageLayout.vue";
import Main from "../components/Landing/Main.vue";
import AllCourses from "../components/SearchCourse/AllCourses.vue";
export default {
  components: {
    LandingPageLayout,
    Main,
    AllCourses
  },
  mounted() {
    this.$emit("update:layout", "main");
    document.querySelector("body").classList.add("hide-overflow");
  },
  unmounted() {
    document.querySelector("body").classList.remove("hide-overflow");
  }
};
</script>

<style lang="scss" scoped>
.section {
  max-width: 960px;
  padding-top: 40px;
  padding-bottom: 80px;
}
.courses {
  height: 400px;
}
@media screen and (max-width: 786px) {
  .section {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .courses {
    height: 450px;
  }
}
@media screen and (max-width: 300px) {
  .courses {
    height: 700px;
  }
}
@media (min-width: 300px) and (max-width: 338px) {
  .courses {
    height: 650px;
  }
}
@media (min-width: 338px) and (max-width: 409px) {
  .courses {
    height: 600px;
  }
}
@media (min-width: 409px) and (max-width: 455px) {
  .courses {
    height: 500px;
  }
}
@media (min-width: 455px) and (max-width: 568px) {
  .courses {
    height: 500px;
  }
}
</style>
