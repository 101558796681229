import { mapGetters } from "vuex";
import store from "../../../store";
export default {
  data() {
    return {
      isCoursesLoading: true,
      filter: {
        language: ""
      }
    };
  },
  computed: {
    ...mapGetters([
      "allCourses",
      "allSemanticallySimilarCourses",
      "isLoadMore",
      "getPageNumber",
      "getSearchText",
      "getCount",
      "getCourseLanguage"
    ]),
    search: {
      get() {
        return this.getSearchText;
      },

      set(searchText) {
        store.commit("SET_SEARCH_TEXT", searchText);
      }
    },
    courses() {
      return this.allCourses.slice(0, 12);
    }
  },
  watch: {
    filter: {
      handler(v) {
        store.commit("SET_COURSE_LANGUAGE", v.language);
        store.commit("SET_PAGE_NUMBER", 1);
        this.onSearch(true);
      },
      deep: true
    },
    search() {
      store.commit("SET_PAGE_NUMBER", 1);
      this.onSearch(true);
    }
  },
  methods: {
    loadMore(includingSemanticResults = false) {
      store.commit("SET_PAGE_NUMBER", this.getPageNumber + 1);
      this._getAllCourses(includingSemanticResults);
    },
    onSearch(includingSemanticResults = false) {
      this._getAllCourses(includingSemanticResults);
    },
    _getAllCourses(includingSemanticResults = false) {
      store.dispatch("getCourses", includingSemanticResults).then(() => {
        this.isCoursesLoading = false;
        this.isSearchActive = true;
      });
    }
  }
};
