export default {
  methods: {
    navToCourseDetail(c) {
      this.$router.push({
        name: "Courses Detail",
        params: { id: c?.id },
        query: { from: "discovery", source: c?.source }
      });
    }
  }
};
